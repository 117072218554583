import React from "react";
import { Typography, Card, CardMedia, Stack } from "@mui/material";
import { FontStyle } from '../assets/styles';

const Our = ({ image, title, subtitle, description }) => {
  return (
    <Stack
      sx={{
        gap: 4,
        width: { xs: "100%", md: "45%" },
        alignItems: { xs: "center", md: "flex-start" },
        '@media(max-width:600px)': {
          gap: 1,
          flexDirection: "column-reverse"
        }
      }}
    >
      <Stack sx={{ textAlign: { xs: "left", md: "left" }, }}> {/* Center text on mobile */}
        <Typography sx={{ color: "#EFA857", ...FontStyle.body43 }}>
          {title}
        </Typography>
        <Typography sx={{ ...FontStyle.sub42,  }}>
          {subtitle}
        </Typography>
        <Typography sx={{ mt: 2, ...FontStyle.body54 }}>
          {description}
        </Typography>
      </Stack>
      <Card
        sx={{
          borderRadius: "20px",
          width: "100%", // Use full width
          height: { xs: "50vw", md: "23.3vw" }, // Responsive height
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt="Vision Image"
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Card>
    </Stack>
  );
};

export default Our;
