import React from "react";
import { useMediaQuery } from "@mui/material";

const Button = ({ text, onClick, backgroundColor, textColor, fontSize }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const buttonStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: isMobile ? "12px 30px" : "18px 42px",
    gap: "0.5rem",
    width: isMobile ? "120px" : "188px",
    height: isMobile ? "38px" : "60px",
    backgroundColor: backgroundColor || "#303A99",
    borderRadius: "8px",
    color: textColor || "#ffffff",
    fontFamily: "Figtree",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: fontSize || isMobile ? "10px" : "17px",
    cursor: "pointer",
    border: "none",
    flex: "none",
    order: 1,
    flexGrow: 0,
  };

  return (
    <button style={buttonStyles} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;

// import React from "react";

// const Button = ({ text, onClick, backgroundColor, textColor }) => {
//   const buttonStyles = {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '18px 42px', // Fixed padding syntax
//     gap: '0.5rem',
//     width: '178px',
//     height: '60px',
//     backgroundColor: backgroundColor ? backgroundColor : '#303A99',
//     borderRadius: '8px',
//     color: textColor ? textColor : '#ffffff',
//     fontFamily: 'Figtree',
//     fontStyle: 'normal',
//     fontWeight: 600,
//     fontSize: '16px',
//     cursor: 'pointer',
//     border: 'none',
//     flex: 'none',
//     order: 1,
//     flexGrow: 0,
//   };

//   const responsiveButtonStyles = {
//     ...buttonStyles,
//     '@media (max-width: 600px)': {
//       width: '150px',
//       height: '50px',
//       padding: '12px 30px',
//       fontSize: '14px',
//     },
//   };

//   return (
//     <button style={responsiveButtonStyles} onClick={onClick}>
//       {text}
//     </button>
//   );
// };

// export default Button;
