import { colors, Typography, Box, Stack } from "@mui/material";
import React from "react";
import { FontStyle } from "../assets/styles";

const Owner1 = ({ imageSrc, linkedin, title, description, subtitle, href }) => {
    const styles = {
        container: {
            width: '27vw',
            minWidth: "320px",
            height: "38vw",
            minHeight: "720px",
            backgroundColor: 'black',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: "10px",
            '@media(max-width:360px)': {
                height: "23.6vw",
                minHeight: "490px",
                gap:1
            }
        },
        imageContainer: {
            width: '100%',
            height: "17.1vw",
            minHeight: "240px",
            minWidth: "100%",
            borderRadius: "8px",
            '@media(max-width:360px)': {
                height: "10.6vw",
                minHeight: "204px",
            }
        },
        image: {
            width: '100%',
            height: '100%',
            minWidth: "100%",
            minHeight: "100%",
            objectFit: "cover",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            '@media(max-width:360px)': {
                height: "10.6vw",
                minHeight: "204px",
            }
        },
        textContainer: {
            gap: 2,
            height: "auto",
            color: "white",
            paddingLeft: "24px",
            paddingRight: "24px",
            alignItems: "center",
            '@media(max-width:360px)': {
                gap: 0.4,
            }
        },
    };

    return (
        <Stack sx={styles.container}>
            <Stack sx={styles.imageContainer}>
                <img src={imageSrc} alt="Health Icon" style={styles.image} />
            </Stack>

            <Stack sx={{ height: "31.3%", gap:2,'@media(max-width:360px)': {gap: 0.2}}}>
                <Stack sx={styles.textContainer}>
                    <Typography sx={{ ...FontStyle.body12 }}>
                        {title}
                    </Typography>
                    <Typography sx={{ ...FontStyle.body45 , textAlign:"center"}}>
                        {subtitle}
                    </Typography>
                    <Typography sx={{ ...FontStyle.body61, textAlign:"center" }}>
                        {description}
                    </Typography>
                </Stack>

                <Stack paddingX={4} paddingY={1} alignItems={"center"} mt={1} gap={1}>
                    <Box sx={{ width: "100%", background: '#E6E8EC', height: "1px" }}>
                    </Box>
                    
   
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="LinkedIn" style={{ height: "42px", width: "42px" }} />
                    </a>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Owner1;  