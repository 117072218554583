import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({ logoImage, style }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(open);
  };

  return (
    <Box
      sx={{
        height: "34px",
        width: "100%",
        display: { xs: "flex", sm: "none" }, // Show only on mobile screens
        justifyContent: "space-between",
        alignItems: "center",
        pt: "20px",
        pb: "20px",
      }}
    >
      <img
        src={logoImage}
        alt="Logo"
        style={{
          height: "34px",
          width: "186.71px",
          ...style
        }}
      />

      <IconButton edge="end" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ fontSize: { xs: "24px", sm: "28px" }, color: "#392874" }} />
      </IconButton>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "250px",
            backgroundColor: "#fff",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ fontSize: "24px", color: "#392874" }} />
          </IconButton>
        </Box>
        <List>
          <ListItem button onClick={() => { navigate("/"); }}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/about"); }}>
            <ListItemText primary="About" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/product"); }}>
            <ListItemText primary="Products" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/contact"); }}>
            <ListItemText primary="Contact us" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Header;
