import React from "react";
import { Box } from "@mui/material";

const Layout = ({ children, backgroundColor }) => {
  return (
    <Box
      sx={{
        width: "calc(100% - 10.8%)",
        position: "relative",
        paddingLeft: "5.4%",
        paddingRight: "5.4%",
        background: backgroundColor,
        "@media (max-width: 600px)": {
          width: "calc(100% - 10.8%)",
          position: "relative",
          paddingLeft: "5.4%",
          paddingRight: "5.4%",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Layout;
